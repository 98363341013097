<template>
  <div>
    <v-row
      v-if="authShareholder.id && authShareholder.company"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="10"
        lg="8"
        xl="7"
      >
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
          >
            <v-card
              outlined
              class="rounded-lg"
              :class="{
                'grey darken-3': $vuetify.theme.dark,
              }"
            >
              <v-card-text>
                <table class="company-info-table">
                  <thead>
                    <tr>
                      <th class="w-50" />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Company Name') }}
                      </td>
                      <td>
                        {{ authShareholder.company.name }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Company Type') }}
                      </td>
                      <td>
                        {{ authShareholder.company.company_type.name }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Activity') }}
                      </td>
                      <td>
                        {{ authShareholder.company.activity }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Manager') }}
                      </td>
                      <td>
                        {{ authShareholder.company.manager }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Phone') }}
                      </td>
                      <td>
                        {{ authShareholder.company.phone }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Email') }}
                      </td>
                      <td>
                        {{ authShareholder.company.email }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Website') }}
                      </td>
                      <td>
                        {{ authShareholder.company.website }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Governorate') }}
                      </td>
                      <td>
                        {{ authShareholder.company.governorate.name }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('First Bank') }}
                      </td>
                      <td>
                        {{ authShareholder.company.first_bank.name }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Account Bank') }}
                      </td>
                      <td>
                        {{ authShareholder.company.account_bank.name }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Business Name Date') }}
                      </td>
                      <td>
                        {{ authShareholder.company.name_date }}
                      </td>
                    </tr>
                    <!-- <tr>
                      <td class="font-weight-bold">
                      {{ $t('Business Name Number') }}
                      </td>
                      <td>
                        {{ authShareholder.company.name_number }}
                      </td>
                    </tr> -->
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Capital') }}
                      </td>
                      <td>{{ $_number_format(authShareholder.company.capital) }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Registration Number') }}
                      </td>
                      <td>
                        {{ authShareholder.company.company_registration_number }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Company Registration Date') }}
                      </td>
                      <td>{{ $_date_format(authShareholder.company.company_registration_date) }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        {{ $t('Unique Company Business No.') }}
                      </td>
                      <td>
                        {{ authShareholder.company.manager_shareholder_business_no }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
          >
            <v-row>
              <v-col cols="12">
                <v-card
                  outlined
                  class="rounded-lg"
                  :class="{
                    'grey darken-3': $vuetify.theme.dark,
                  }"
                >
                  <v-card-text>
                    <table class="company-info-table">
                      <thead>
                        <tr>
                          <th class="w-60" />
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            colspan="2"
                            class="font-weight-bold text-start pb-3"
                          >
                            {{ $t('Dos Numbers') }}
                          </td>
                        </tr>
                        <!-- <tr>
                          <td>{{ $t('Supporter Accountant') }}</td>
                          <td class="font-weight-bold">
                            {{ authShareholder.company.s_accountant }}
                          </td>
                        </tr> -->
                        <!-- <tr>
                          <td>{{ $t('Worker Accountant') }}</td>
                          <td class="font-weight-bold">
                            {{ authShareholder.company.w_accountant }}
                          </td>
                        </tr> -->
                        <!-- <tr>
                          <td>{{ $t('Accountant Contract Date') }}</td>
                          <td class="font-weight-bold">
                            {{ authShareholder.company.accountant_contract_date }}
                          </td>
                        </tr> -->
                        <!-- <tr>
                          <td>{{ $t('Supporter Lawyer') }}</td>
                          <td class="font-weight-bold">
                            {{ authShareholder.company.s_lawyer }}
                          </td>
                        </tr> -->
                        <!-- <tr>
                          <td>{{ $t('Worker Lawyer') }}</td>
                          <td class="font-weight-bold">
                            {{ authShareholder.company.w_lawyer }}
                          </td>
                        </tr> -->
                        <!-- <tr>
                          <td>{{ $t('Lawyer Contract Date') }}</td>
                          <td class="font-weight-bold">
                            {{ authShareholder.company.lawyer_contract_date }}
                          </td>
                        </tr> -->
                        <tr>
                          <td class="font-weight-bold">
                            {{ $t('Dos Number In Companies') }} / {{ $t(authShareholder.company.dos_number_place_text) }}
                          </td>
                          <td>
                            {{ authShareholder.company.dos_number_in_co }}
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold">
                            {{ $t('Dos Number In Companies/General') }}
                          </td>
                          <td>
                            {{ authShareholder.company.dos_number_in_co_general }}
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold">
                            {{ $t('Dos Number In Tax Office') }} / {{ $t(authShareholder.company.dos_number_place_text) }}
                          </td>
                          <td>
                            {{ authShareholder.company.dos_number_in_tax_office }}
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold">
                            {{ $t('Real Beneficiary Form Number') }}
                          </td>
                          <td>
                            {{ authShareholder.company.real_beneficiary_form_number }}
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold">
                            {{ $t('Social Security Employee Number') }} / {{ $t(authShareholder.company.dos_number_place_text) }}
                          </td>
                          <td>
                            {{ authShareholder.company.social_security_number_employee }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12">
                <v-card
                  outlined
                  class="rounded-lg"
                >
                  <v-card-text>
                    <v-row
                      no-gutters
                      class="mt-3"
                    >
                      <v-col
                        cols="12"
                        class="font-weight-bold pb-2"
                      >
                        {{ $t('Shareholders') }}
                      </v-col>

                      <v-col
                        v-for="(shareholder, index) in shareholders"
                        :key="shareholder.id"
                        cols="12"
                        class="mt-1 mb-1"
                      >
                        <div
                          class="d-flex justify-space-between align-center rounded px-2 py-1"
                          :style="`background-color: ${shareholderColors[index]}`"
                        >
                          <div>
                            <v-avatar size="34">
                              <img
                                :src="shareholder.profile_picture ? `${$_file_path()}${shareholder.profile_picture}?token=${$_auth_token()}`: $_profile_placeholder()"
                                :alt="shareholder.username"
                              >
                            </v-avatar>
                            {{ shareholder.first_name }}
                          </div>

                          <div>
                            %{{ shareholder.share_percentage }}
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      shareholders: [],
      shareholderColors: [
        '#4CAF506F',
        '#2196F36F',
        '#FF98006F',
        '#0096886F',
        '#3F51B56F',
        '#E91E636F',
        '#9C27B06F',
        '#673AB76F',
      ]
    }
  },

  computed: {
    ...mapGetters(['authShareholder'])
  },

  mounted() {
    this.getShareholders()
  },

  methods: {
    getShareholders() {
      axios.get('company/client/my-company-shareholders/').then(res => {
        this.shareholders = res.data
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.company-info-table tbody > tr > td {
  padding: 0.4rem 0.2rem;
}
</style>